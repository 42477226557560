

import { computed, defineComponent, ref } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import LoginForm from '@/components/LoginForm.vue';
import SignupForm from '@/components/SignupForm.vue';
  
//  
//

export default defineComponent({
  name: 'soc-exile', 
  components: {
    LoginForm
  },

  setup(): StringToAny {
    const isSignupMode = ref(false);
    const theForm = computed(() => {
      return isSignupMode.value ? SignupForm : LoginForm;
    });

    return {
      isSignupMode,
      theForm,
    };
  },

});
