<template>
<div class='exile'>

  <div class='wrapper'>
    <div class='heading-area'>
      <button
          class = 'clickable-heading'
          @click = 'isSignupMode = true'
          :disabled = 'isSignupMode'
      > sign up </button>

      <button
          class = 'clickable-heading'
          @click = 'isSignupMode = false'
          :disabled = '!isSignupMode'
      > log in </button>
    </div>
    <component :is='theForm'></component>
  </div>

</div> 
</template>

<!---------------------------------------------------------------------------->
<script lang='ts'>

import { computed, defineComponent, ref } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import LoginForm from '@/components/LoginForm.vue';
import SignupForm from '@/components/SignupForm.vue';
  
//  
//

export default defineComponent({
  name: 'soc-exile', 
  components: {
    LoginForm
  },

  setup(): StringToAny {
    const isSignupMode = ref(false);
    const theForm = computed(() => {
      return isSignupMode.value ? SignupForm : LoginForm;
    });

    return {
      isSignupMode,
      theForm,
    };
  },

});
</script>

<!---------------------------------------------------------------------------->

<style scoped>

.exile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 30vh 0 0 0;
  padding: 0;
}

.heading-area {
  display: flex;
  margin-bottom: 1rem;

  align-items: center;
  justify-content: space-around;
}

.clickable-heading {
  margin: auto 1rem;

  background: none;
  border: none;
  color: black;
  font-size: 0.8rem;

  outline: none;
  
  transition: all 0.1s linear;
}
.clickable-heading:disabled {
  font-size: 2rem;
  font-weight: bold;
}
.clickable-heading:focus, .clickable-heading:enabled:hover {
  color: orange;
}

</style>
