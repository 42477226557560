import { StringToAny } from 'helpist/lib/types';

//
//

export enum Why {
  BadCredentials = 0,
  NoReasonFound = 1,
  NoTokenReturned = 2,
  SinepCodeAlreadyRedeemed = 3,
  SinepCodeHasExpired = 4,
  StaleUpdateAttempt = 5,
  UserNameAlreadyTaken = 6,
}

export const parseWhy = (error: StringToAny): Why => {
  // unauthorized
  if (error.response?.status === 401) {
    return Why.BadCredentials;
  }
  // not acceptable
  if (error.response?.status === 406) {
    return Why.UserNameAlreadyTaken;
  }

  const stir = error.message || '';
  console.log('the stir is ' + stir);

  if (stir.includes('SINEP_CODE_ALREADY_REDEEMED')) {
    return Why.SinepCodeAlreadyRedeemed;
  }
  if (stir.includes('SINEP_CODE_HAS_EXPIRED')) {
    return Why.SinepCodeHasExpired;
  }
  if (stir.includes('STALE_UPDATE_ATTEMPT')) { return Why.StaleUpdateAttempt; }

  return Why.NoReasonFound;
};
