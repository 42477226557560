import { Errors } from '../../../utility/errors';
import { TypeUtility as TU } from '../../../utility/type';
//
//
const hasWorth = (fieldName, value) => {
    return !fieldName.startsWith('_') && value !== null && value !== undefined;
};
const getCleanVersionOf = (filthy) => {
    const ohbeejay = {};
    for (const fieldName of Object.getOwnPropertyNames(filthy)) {
        const value = filthy[fieldName];
        if (hasWorth(fieldName, value)) {
            ohbeejay[fieldName] = value;
        }
    }
    return ohbeejay;
};
//
// the actual class
//
export class ResponseProbist {
    constructor(requestName) {
        this.requestName = requestName;
    }
    // get the string value of a field by the field name
    getStringField(response, fieldName) {
        const ohbeejay = this.extract(response);
        if (!Object.prototype.hasOwnProperty.call(ohbeejay, fieldName)) {
            throw Errors.noSuchKey(fieldName);
        }
        return ohbeejay[fieldName];
    }
    extractFieldAsObject(result, key) {
        const wire = this.extract(result);
        if (!(key in wire)) {
            throw Errors.noSuchKey(key);
        }
        const value = wire[key];
        TU.verifyIsObjectLike(value);
        return value;
    }
    extractFieldAsArray(result, key) {
        const wire = this.extract(result);
        if (!(key in wire)) {
            throw Errors.noSuchKey(key);
        }
        const value = wire[key];
        if (!(value instanceof Array)) {
            throw Errors.parse(value, 'array');
        }
        const filthyArray = value;
        const cleanArray = [];
        for (const filthy of filthyArray) {
            cleanArray.push(getCleanVersionOf(filthy));
        }
        return cleanArray;
    }
    static getCleanVersionOf(filthy) {
        const ohbeejay = {};
        for (const fieldName of Object.getOwnPropertyNames(filthy)) {
            const value = filthy[fieldName];
            if (hasWorth(fieldName, value)) {
                ohbeejay[fieldName] = value;
            }
        }
        return ohbeejay;
    }
    extract(result) {
        if (!Object.prototype.hasOwnProperty.call(result, 'data')) {
            throw Errors.noSuchKey('data');
        }
        const data = result.data;
        if (!Object.prototype.hasOwnProperty.call(data, this.requestName)) {
            throw Errors.noSuchKey(this.requestName);
        }
        return data[this.requestName];
    }
}
