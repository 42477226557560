<template>
<div class='login-form'>

  <form class='the-form' @submit.prevent='handleSubmit'>
    <div class='the-table'>

      <div v-if='shouldShowError' class='errorea has-text-danger'>
        {{ errorMessage }}
      </div>

      <div class='label-cell'>
        <label class='label'> user name </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'text'
          v-model = 'userName'
          size = 15
          @input = 'validate' />
      </div>

      <div class='label-cell'>
        <label class='label'> password </label>
      </div>
      <div class='value-cell'>
        <input
          class = 'input'
          type = 'password'
          v-model = 'password'
          size = 15
          @input = 'validate' />
      </div>

      <transition name='grow'>
      <div v-show='allFieldsAreValid' class='button-div'>
          <button class='button'> submit </button>
      </div>
      </transition>

    </div>
  </form>

</div>
</template>

<!---------------------------------------------------------------------------->

<script lang='ts'>

import { defineComponent, inject, ref } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import { AuthenticationService } from '@/authentication/service';

import { Result } from '@/traffic/result';
import { ResultCode } from '@/traffic/result/code';
import { Why } from '@/traffic/result/why';

//
//

export default defineComponent({

  setup(): StringToAny {

    const service =
      inject(AuthenticationService.InjectionKey) as AuthenticationService;

    const userName = ref('');
    const password = ref('');

    const allFieldsAreValid = ref(false);
    const shouldShowError = ref(false);
    const errorMessage = ref('');

    // handlers
    const handleResult = (result: Result) => {
      if (result.code === ResultCode.Success) {
        window.location.href = '/';
      } else {
        if (result.why === Why.BadCredentials) {
          errorMessage.value = 'user name or password was incorrect =/';
        } else {
          errorMessage.value = 'an error was happened';
        }
        shouldShowError.value = true;
        password.value = '';
      }
    };
    const handleSubmit = () => {
      const hashword = service.hashSlowly(password.value, userName.value);
      service.logIn(userName.value, hashword).then(handleResult);
    };

    const validate = () => {
      if (userName.value.length < 3) {
        allFieldsAreValid.value = false;
      } else if (password.value.length < 5) {
        allFieldsAreValid.value = false;
      } else {
        allFieldsAreValid.value = true;
      }
    };


    return {
      allFieldsAreValid,
      errorMessage,
      shouldShowError,

      // the actual values
      password,
      userName,

      // functions
      handleSubmit,
      validate,
    } as StringToAny;
  },

});

</script>

<!---------------------------------------------------------------------------->

<style scoped>

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 10rem;
}

.the-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
}

.errorea {
  grid-column: span 2;
  text-align: center;

  font-weight: bold;
}

.label-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.value-cell {
  display: inline-block;
}

.input {
  width: auto;
}

.button-div {
  grid-column: span 2;

  display: flex;
  justify-content: center;
  max-height: 0;
}

.button {
  margin-top: 0.5rem;
  color: black;
  background-color: orange;
  font-weight: bold;
}

.grow-enter-active,
.grow-leave-active {
  transition: all 0.1s linear;
}

.grow-enter-from,
.grow-leave-to {
  transform: scale(0);
}

</style>
