
import { defineComponent } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import TheExile from './views/Exile.vue';

//
//

export default defineComponent({
  components: {
    TheExile,
  },

  setup(): StringToAny {
    return {} as StringToAny;
  },
});
