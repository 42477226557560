<template>
  <TheExile />
</template>

<!---------------------------------------------------------------------------->

<script lang="ts">
import { defineComponent } from 'vue';

//

import { StringToAny } from 'helpist/lib/types';

//

import TheExile from './views/Exile.vue';

//
//

export default defineComponent({
  components: {
    TheExile,
  },

  setup(): StringToAny {
    return {} as StringToAny;
  },
});
</script>

<!---------------------------------------------------------------------------->

<style>
@import './assets/styles.css';

#app {
  height: 100%;
}
</style>
