import { createApp } from 'vue'

//

import App from '@/App.vue'

import { AuthenticationService } from '@/authentication/service';

//
//

const app = createApp(App);

const authenticationService = new AuthenticationService();
app.provide(AuthenticationService.InjectionKey, authenticationService);

app.mount('#app');
