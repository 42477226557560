import { ResultCode } from './code';
import { Why } from './why';

//

export class Result {
  constructor(public code: ResultCode, public why?: Why) {}

  static failure(why: Why): Result {
    return new Result(ResultCode.Failure, why);
  }

  static success(): Result {
    return new Result(ResultCode.Success);
  }
}
